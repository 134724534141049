<template>
  <div class="container" ref="container">
    <div>
      <h3>알림</h3>

      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <label-button
        title="메세지 알림"
        subtitle="채팅 메세지가 도착했을 때"
        right-icon="toggle"
        :toggle-default="push.message"
        @click="(val) => {push.message = val; pushSetting(val)}"
      >
      </label-button>
      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <label-button
        title="소식 알림"
        :subtitle="isClient ? '등록한 의뢰에 새로운 지원자가 있을 때' : '새로운 의뢰가 등록되었을 때'"
        right-icon="toggle"
        :toggle-default="push.alarm"
        @click="(val) => {push.alarm = val; pushSetting(val)}"
      >
      </label-button>

      <template v-if="!isClient">
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

        <label-button
          title="알림 받을 분야"
          subtitle="알림을 받고 싶은 분야를 고를 수 있어요."
          right-icon="next"
          @click="() => $gotoWeb('/setting/alarm')"
        >
        </label-button>

        <!-- 멤버십 무료 전환-->
        <!-- <template v-if="isNewMembershipUser"> -->
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

        <!-- 멤버십 무료 전환 - 텍스트 수정-->
        <label-button
          title="알림 받을 지역"
          subtitle="선택한 지역의 의뢰 알림만 받을 수 있어요."
          right-icon="next"
          @click="() => $gotoWeb('/setting/alarm-locale')"
        >
        </label-button>
      </template>
    </div>

    <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

    <div>
      <h3>이용약관</h3>

      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <label-button
        title="서비스 이용약관"
        right-icon="next"
        @click="() => $gotoWeb('/etc/terms')"
      >
      </label-button>

      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <label-button
        title="개인정보 취급방침"
        right-icon="next"
        @click="() => $gotoWeb('/etc/privacy')"
      >
      </label-button>
    </div>

    <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

    <div>
      <h3>기타</h3>

      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <!--
      <template v-if="!isClient">
        <label-button
          title="수수료 안내"
          right-icon="next"
          @click="() => $gotoWeb('/etc/fee-information')"
        >
        </label-button>
        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </template>
      -->

      <!--
      <label-button
        title="이용안내"
        right-icon="next"
        @click="() => $gotoWeb('/etc/information')"
      >
      </label-button>
      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      -->

      <label-button
        title="카카오톡 1:1문의"
        right-icon="next"
        @click="() => $gotoWeb('http://pf.kakao.com/_yzxhwC')"
      >
      </label-button>
      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <label-button
        title="버전 정보"
      >
        <div :style="{ marginRight: '1.2rem' }">2.0.0</div>
      </label-button>

      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <label-button
        title="로그아웃"
        @click="() => {
          $gotoWeb('/logout')
        }"
      >
        <div :style="{ marginRight: '-0.8rem' }">
          <icon v-if="sns === 'apple'" src="img/sns/apple.png" width="3rem" height="7.5rem" :cover="false" origin-url />
          <icon v-if="sns === 'naver'" src="img/sns/naver.png" width="3rem" height="7.5rem" :cover="false" />
          <icon v-if="sns === 'kakao'" src="img/sns/kakao.png" width="3rem" height="7.5rem" :cover="false" />
          <icon v-if="sns === 'facebook'" src="img/sns/facebook.png" width="3rem" height="7.5rem" :cover="false" />
        </div>
      </label-button>

      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <template v-if="false">
        <label-button
          title="회원탈퇴"
          @click="() => $refs.OutModal.show()"
          right-icon="next"
        >
        </label-button>

        <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
      </template>
    </div>

    <modal
      ref="OutModal"
      confirm="탈퇴합니다"
      :confirmCb="() => unsign()"
      max-width="46rem"
      id="out-modal"
    >
      <div slot="header" class="w-100" :style="{ display: 'block', textAlign: 'center' }">
        <icon src="img/illustration/sad.png" width="9.5rem" height="11.5rem" :cover="false"/>
        <h5 class="modal-title">{{'잠깐만요\n정말로 탈퇴를?'}}</h5>
      </div>
      <div slot="body">{{'회원탈퇴시 작성한 모든\n컨텐츠는 복구할 수 없어요.\n\n정말로 탈퇴하시겠어요?'}}</div>
    </modal>
  </div>
</template>

<script>
import LabelButton from '@/components/common/LabelButton'
import Modal from '@/components/common/Modal'
import Icon from '@/components/common/Icon'

export default {
  name: 'Setting',
  components: {
    LabelButton,
    Modal,
    Icon,
  },
  data() {
    const userData = this.$store.state.user.userData
    const message = userData.pushChatOn === 'on'
    const alarm = userData.pushOn === 'on'
    return {
      push: {
        message,
        alarm,
      },
      scroll: {
        lastY: 0,
      },
      history: {
        exit: false,
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    isClient() {
      return this.userData.category === 'client'
    },
    sns() {
      return localStorage.getItem('sns')
    },
    isNewMembershipUser() {
      return this.$store.getters.isNewMembershipUser
    },
  },
  created() {
    this.$eventBus.$on('refresh', route => {
      if (route.path === '/setting') {
        this.$nextTick()
          .then(() => {
            this.$refs?.container?.scrollTo?.({ top: 0 })
          })
      }
    })
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      Vue.$refs.container.scrollTop = Vue.scroll.lastY
    })
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (this.$store.state.history.back) {
        this.$runToApp({
          androidCb: () => {
            if (!this.history.exit) {
              this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
              this.history.exit = true
              setTimeout(() => (this.history.exit = false), 2000)
            } else {
              location.href = 'hbscheme://appExit'
            }
            next(false)
          },
          iosCb: () => next(false),
          webCb: () => next(false),
        })
      } else {
        this.scroll.lastY = this.$refs.container.scrollTop
        next()
      }
    })
  },
  methods: {
    /**
     * @param {boolean} [on]
     */
    pushSetting(on) {
      const pushOn = this.push.alarm ? 'on' : 'off'
      const pushChatOn = this.push.message ? 'on' : 'off'
      const userData = { pushOn, pushChatOn }
      const req = {
        method: 'put',
        url: `/user/pushSetting/${pushOn}`,
        data: {
          chat: pushChatOn,
        }
      }

      this.$store.commit('setUserData', userData)

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$toast(`알림이 ${on ? '설정(ON)' : '해제(OFF)'}되었습니다.`)
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    unsign() {
      const req = {
        method: 'put',
        url: `/user/out`,
      }

      this.$refs.OutModal.hide()

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$toast('정상적으로 탈퇴되었습니다').push('/logout')
          } else if (resultData.code === -2) {
            this.$toast('탈퇴가 불가능한 계정입니다')
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
  },
}
</script>

<style lang="scss">
#out-modal.modal {
  .modal-header {
    border: 0;
    margin-bottom: -2rem;
    .icon {
      margin: 2rem auto 0;
    }
    h5 {
      white-space: pre-line;
      margin-top: 2rem;
      font-weight: 500;
      font-size: 3.2rem;
      line-height: 1.2em;
      color: #000;
    }
  }
  .modal-body {
    white-space: pre-line;
    padding: 2rem 0;
    font-size: 2.4rem;
    line-height: 3rem;
    text-align: center;
    margin-bottom: 4rem;
  }
}
</style>


<style lang="scss" scoped>
#page h3 {
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: -2rem;
}
.ui-border-line {
  margin-left: 0rem;
  margin-right: 0rem;
}
</style>
