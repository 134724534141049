var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "container" },
    [
      _c(
        "div",
        [
          _c("h3", [_vm._v("알림")]),
          _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
          _c("label-button", {
            attrs: {
              title: "메세지 알림",
              subtitle: "채팅 메세지가 도착했을 때",
              "right-icon": "toggle",
              "toggle-default": _vm.push.message
            },
            on: {
              click: function(val) {
                _vm.push.message = val
                _vm.pushSetting(val)
              }
            }
          }),
          _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
          _c("label-button", {
            attrs: {
              title: "소식 알림",
              subtitle: _vm.isClient
                ? "등록한 의뢰에 새로운 지원자가 있을 때"
                : "새로운 의뢰가 등록되었을 때",
              "right-icon": "toggle",
              "toggle-default": _vm.push.alarm
            },
            on: {
              click: function(val) {
                _vm.push.alarm = val
                _vm.pushSetting(val)
              }
            }
          }),
          !_vm.isClient
            ? [
                _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
                _c("label-button", {
                  attrs: {
                    title: "알림 받을 분야",
                    subtitle: "알림을 받고 싶은 분야를 고를 수 있어요.",
                    "right-icon": "next"
                  },
                  on: {
                    click: function() {
                      return _vm.$gotoWeb("/setting/alarm")
                    }
                  }
                }),
                _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
                _c("label-button", {
                  attrs: {
                    title: "알림 받을 지역",
                    subtitle: "선택한 지역의 의뢰 알림만 받을 수 있어요.",
                    "right-icon": "next"
                  },
                  on: {
                    click: function() {
                      return _vm.$gotoWeb("/setting/alarm-locale")
                    }
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
      _c(
        "div",
        [
          _c("h3", [_vm._v("이용약관")]),
          _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
          _c("label-button", {
            attrs: { title: "서비스 이용약관", "right-icon": "next" },
            on: {
              click: function() {
                return _vm.$gotoWeb("/etc/terms")
              }
            }
          }),
          _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
          _c("label-button", {
            attrs: { title: "개인정보 취급방침", "right-icon": "next" },
            on: {
              click: function() {
                return _vm.$gotoWeb("/etc/privacy")
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
      _c(
        "div",
        [
          _c("h3", [_vm._v("기타")]),
          _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
          _c("label-button", {
            attrs: { title: "카카오톡 1:1문의", "right-icon": "next" },
            on: {
              click: function() {
                return _vm.$gotoWeb("http://pf.kakao.com/_yzxhwC")
              }
            }
          }),
          _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
          _c("label-button", { attrs: { title: "버전 정보" } }, [
            _c("div", { style: { marginRight: "1.2rem" } }, [_vm._v("2.0.0")])
          ]),
          _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
          _c(
            "label-button",
            {
              attrs: { title: "로그아웃" },
              on: {
                click: function() {
                  _vm.$gotoWeb("/logout")
                }
              }
            },
            [
              _c(
                "div",
                { style: { marginRight: "-0.8rem" } },
                [
                  _vm.sns === "apple"
                    ? _c("icon", {
                        attrs: {
                          src: "img/sns/apple.png",
                          width: "3rem",
                          height: "7.5rem",
                          cover: false,
                          "origin-url": ""
                        }
                      })
                    : _vm._e(),
                  _vm.sns === "naver"
                    ? _c("icon", {
                        attrs: {
                          src: "img/sns/naver.png",
                          width: "3rem",
                          height: "7.5rem",
                          cover: false
                        }
                      })
                    : _vm._e(),
                  _vm.sns === "kakao"
                    ? _c("icon", {
                        attrs: {
                          src: "img/sns/kakao.png",
                          width: "3rem",
                          height: "7.5rem",
                          cover: false
                        }
                      })
                    : _vm._e(),
                  _vm.sns === "facebook"
                    ? _c("icon", {
                        attrs: {
                          src: "img/sns/facebook.png",
                          width: "3rem",
                          height: "7.5rem",
                          cover: false
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
          false
            ? [
                _c("label-button", {
                  attrs: { title: "회원탈퇴", "right-icon": "next" },
                  on: {
                    click: function() {
                      return _vm.$refs.OutModal.show()
                    }
                  }
                }),
                _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" })
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "modal",
        {
          ref: "OutModal",
          attrs: {
            confirm: "탈퇴합니다",
            confirmCb: function() {
              return _vm.unsign()
            },
            "max-width": "46rem",
            id: "out-modal"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "w-100",
              style: { display: "block", textAlign: "center" },
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("icon", {
                attrs: {
                  src: "img/illustration/sad.png",
                  width: "9.5rem",
                  height: "11.5rem",
                  cover: false
                }
              }),
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(_vm._s("잠깐만요\n정말로 탈퇴를?"))
              ])
            ],
            1
          ),
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _vm._v(
              _vm._s(
                "회원탈퇴시 작성한 모든\n컨텐츠는 복구할 수 없어요.\n\n정말로 탈퇴하시겠어요?"
              )
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }